import { ProductLine, Invoice } from './types'

export const initialProductLine: ProductLine = {
  description: '',
  quantity: '1',
  rate: '0.00',
  gst: '0',
}

export const initialInvoice: Invoice = {
  logo: '',
  logoWidth: 100,
  // upi: '',
  // upiWidth: 100,
  title: 'ബില്ല്',
  companyName: '',
  name: '',
  phone: '',
  mail: '',
  companyAddress: '',
  companyAddress2: '',
  companyCountry: '',
  billTo: 'അടയ്‌ക്കുന്നയാൾ:',
  clientName: '',
  clientPhone: '',
  clientMail: '',
  clientAddress: '',
  clientAddress2: '',
  clientCountry: '',
  invoiceTitleLabel: 'ബിൽ നം.',
  invoiceTitle: '',
  invoiceGSTINLabel: 'GSTIN',
  invoiceGSTIN: '',
  invoiceDateLabel: 'തീയതി',
  invoiceDate: '',
  invoiceDueDateLabel: 'അവസാന തീയതി',
  invoiceDueDate: '',
  productLineDescription: 'വിവരണം',
  productLineQuantity: 'എണ്ണം',
  productLineQuantityRate: 'വില',
  productLineQuantityAmount: 'തുക',
  productLineGST: 'GST(%)',
  productLines: [
    {
      description: 'പോസ്റ്റർ ഡിസൈൻ ',
      quantity: '2',
      rate: '100.00',
      gst: '0',
    },
    { ...initialProductLine },
    { ...initialProductLine },
  ],
  subTotalLabel: 'ആകെ തുക',
  sgstLabel: 'SGST',
  cgstLabel: 'CGST',
  roundLabel: 'റൗണ്ട് ഓഫ്',
  totalLabel: 'മൊത്തം ',
  currency: '₹',
  notesLabel: 'കുറിപ്പുകൾ',
  notes: '',
  termLabel: 'നിബന്ധനകളും ഉപാധികളും',
  term: '',
  payLabel: 'പണമടയ്ക്കാൻ',
  pay: '',
}
